@import "../../assets/styles/utils";

body{
  background-color: #fff;
}
.section1{
  @include res(height,calc(100vh - .4rem));
  .swiper{
    height: 100%;
    .swiper-slide{
      overflow: hidden;
      &::after{
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.1);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }
      b{
        display: block;
        height: 100%;
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 0;
      }
      .banner-t{
        position: absolute;
        top: 50%;
        color: #fff;
        width: 100%;
        text-align: center;
        transform: translateY(-50%);
        z-index: 2;
        @include res(padding-left,.3rem);
        @include res(padding-right,.3rem);
        h1{
          font-family: 'song';
          @include res(font-size,.6rem,(md:.7rem,sm:.8rem,xs:.5rem));
          @include res(margin-bottom,.2rem);
        }
        h2{
          font-family: 'Arial','DIN-Medium';
          font-weight: bold;
          @include res(font-size,.24rem,(md:.34rem,sm:.44rem,xs:.3rem));
        }
      }
    }
    .swiper-pagination{
      @include res(bottom,.7rem);
      .swiper-pagination-bullet{
        background-color: transparent;
        border-style: solid;
        border-color: transparent;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        opacity: 1;
        transition: all .3s;
        @include res(margin,0 .15rem);
        @include res(border-width,.01rem,(md:1px));
        @include res(width,.18rem,(md:18px));
        @include res(height,.18rem,(md:18px));
        &::after{
          content: '';
          display: block;
          border-radius: 50%;
          background-color: #fff;
          @include res(width,.1rem,(md:10px));
          @include res(height,.1rem,(md:10px));
        }
        &.swiper-pagination-bullet-active{
          border-color: #fff;
        }
      }
    }
  }
}

.section2{
  align-items: stretch;
  flex-wrap: wrap;
  @include res(display,flex);
  @include res(margin-bottom,1rem,(xs:.6rem));
  .item{
    flex-shrink: 0;
    position: relative;
    flex-grow: 1;
    border-bottom: solid #f1f1f1;
    @include res(border-bottom-width,.01rem,(md:1px));
    @include res(width,20%,(xs:50%));
    &:not(:last-child){
      &::after{
        content: '';
        background-color: #f1f1f1;
        position: absolute;
        right: 0;
        top: 34%;
        transition:opacity .3s;
        @include res(width,.01rem,(md:1px));
        @include res(height,32%);
      }
    }
    &:nth-child(even){
      &::after{
        @include res(display,null,(xs:none));
      }
    }
    .show-cont{
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      transition:color .3s;
      @include res(padding-top,.15rem);
      @include res(padding-bottom,.15rem);
      &::after{
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to right,#41b8ef,#187ec6);
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        opacity: 0;
        transition:opacity .3s;
      }
      img{
        width: auto;
        @include res(height,.9rem,(md:1rem,sm:1.2rem,xs:.8rem));
        @include res(margin-right,.25rem);
      }
      p{
        @include res(font-size,.2rem,(md:.3rem,sm:.4rem,xs:.3rem));
      }
    }
    .hidden-cont{
      position: absolute;
      left: 50%;
      z-index: 2;
      transform: translateX(-50%);
      background-color: #fff;
      transition: all .3s;
      opacity: 0;
      visibility: hidden;
      @include res(bottom,calc(100% + .1rem),(md:calc(100% + .15rem)));
      @include res(padding,.05rem);
      &::after{
        content: '';
        display: block;
        border-style: solid;
        border-color: #fff transparent transparent transparent;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        @include res(border-width,.1rem,(md:.15rem));
      }
      img{
        display: block;
        height: auto;
        @include res(width,1.3rem,(md:1.6rem,sm:1.8rem));
      }
    }
    &:hover{
      &::after{
        opacity: 0;
      }
      .show-cont{
        color: #fff;
        font-weight: bold;
        &::after{
          opacity: 1;
        }
      }
      .hidden-cont{
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.section3{
  position: relative;
  align-items: flex-start;
  justify-content: space-between;
  @include res(display,flex,(sm:block));
  @include res(margin-bottom,.9rem,(xs:.6rem));
  .bg{
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    pointer-events: none;
    img{
      display: block;
      height: auto;
      @include res(width,7.1rem,(sm:8rem,xs:6rem));
    }
  }
  .line{
    width: 100%;
    background-color: #e0e0e0;
    @include res(height,.01rem,(md:1px));
    @include res(margin-bottom,.4rem);
    &::before{
      content: '';
      display: block;
      height: 100%;
      background-color: $color-main;
      @include res(width,1.8rem);
    }
  }
  .left-cont{
    flex-shrink: 0;
    @include res(width,64.5%,(sm:100%));
    @include res(margin-bottom,0,(sm:.9rem,xs:.6rem));
    .headline{
      background-color: #f7f7f7;
      @include res(margin-bottom,.3rem);
      a{
        justify-content: space-between;
        @include res(display,flex,(xs:block));
        .img{
          flex-shrink: 0;
          overflow: hidden;
          @include res(height,18.7vw,(md:21.7vw,sm:30.7vw,xs:62.7vw));
          @include res(width,52%,(xs:100%));
          b{
            display: block;
            height: 100%;
            transition: all .3s;
          }
        }
        .text{
          flex-shrink: 0;
          position: relative;
          @include res(padding-right,.25rem);
          @include res(padding-left,0,(xs:.25rem));
          @include res(padding-bottom,0,(xs:.25rem));
          @include res(width,44%,(xs:100%));
          .date{
            font-family: 'din';
            display: inline-block;
            background-color: $color-main;
            color: #fff;
            text-align: center;
            @include res(font-size, .14rem,(md:.24rem,sm:.34rem,xs:.24rem));
            @include res(padding,.1rem .2rem);
            span{
              display: block;
              @include res(font-size, .24rem,(md:.34rem,sm:.44rem,xs:.3rem));
            }
          }
          .title{
            font-weight: bold;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            transition: color .3s;
            @include res(margin-top,.35rem);
            @include res(margin-bottom,.1rem,(sm:.2rem));
            @include res(font-size,.18rem,(md:.28rem,sm:.38rem,xs:.32rem));
          }
          .desc{
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            color: $color-desc;
            line-height: 1.6;
          }
          .link{
            left: 0;
            @include res(bottom,.2rem);
            @include res(position,absolute,(xs:static));
            @include res(margin-top,0,(xs:.3rem));
            .iconfont{
              color: $color-main;
              line-height: 1;
              @include res(font-size,.4rem,(md:.5rem,sm:.6rem,xs:.3rem));
              @include res(display,block,(xs:inline-block));
              @include res(margin-left,0,(xs:.1rem));
            }
          }
        }
      }
      &:hover{
        a{
          .img b{
              transform: scale(1.05);
          }
          .text .title{
            color: $color-main;
          }
        }
      }
    }
    ul{
      justify-content: space-between;
      @include res(display,flex,(xs:block));
      li{
        flex-shrink: 0;
        @include res(width,45%,(xs:100%));
        &:not(:last-child){
          @include res(margin-bottom,0,(xs:.3rem));
        }
        .date{
          font-family: 'din';
          span{
            display: inline-block;
            color: #fff;
            background-color: $color-main;
            @include res(font-size, .14rem,(md:.24rem,sm:.34rem,xs:.24rem));
            @include res(padding,.1rem .2rem,(md:.15rem .3rem));
          }
        }
        .title{
          font-weight: bold;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          transition: color .3s;
          @include res(margin-top,.2rem);
          @include res(margin-bottom,.1rem,(sm:.2rem));
          @include res(font-size,.18rem,(md:.28rem,sm:.38rem,xs:.32rem));
        }
        .desc{
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          color: $color-desc;
          line-height: 1.6;
        }
        &:hover{
          .title{
            color: $color-main;
          }
        }
      }
    }
  }
  .right-cont{
    flex-shrink: 0;
    @include res(width,28.8%,(md:32%,sm:100%));
    ul{
      background-color: #f7f7f7;
      @include res(padding,0 .25rem);
      li{
        &:not(:last-child){
          border-bottom: solid #e0e0e0;
          @include res(border-bottom-width, .01rem,(md:1px));
        }
        a{
          display: block;
          transition: color .3s;
          line-height: 1.6;
          @include res(padding,.2rem 0);
          p{
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            font-weight: bold;
            @include res(font-size,.18rem,(md:.28rem,sm:.38rem,xs:.32rem));
            // @include res(height,.58rem,(md:.85rem,sm:auto));
            @include res(margin-bottom,.1rem);
          }
        }
        &:hover{
          a{
            color: $color-main;
          }
        }
      }
    }
  }
}

.section4{
  @include res(margin-bottom,1rem,(xs:.6rem));
  ul{
    justify-content: flex-start;
    @include res(display,flex,(xs:block));
    @include res(width,calc(100% + .5rem),(xs:100%));
    li{
      position: relative;
      @include res(height,14.58vw,(md:17.58vw,xs:55vw));
      @include res(width,calc(33.33% - .5rem),(xs:100%));
      @include res(margin-right,.5rem,(xs:0));
      &:not(:last-child){
        @include res(margin-bottom,0,(xs:.3rem));
      }
      .item-img{
        height: 100%;
        overflow: hidden;
        position: relative;
        &::after{
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          transition: all .3s;
          @include res(background-color,rgba(0,0,0,.1),(md:rgba(0,0,0,.2)));
        }
        b{
          display: block;
          height: 100%;
          transition: all .3s;
        }
      }
      .item-text{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        color: #fff;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @include res(padding,0 20%,(md:0 5%));
        .title{
          font-weight: bold;
          transition: all .3s;
          @include res(transform, translateY(300%),(md:translateY(0%)));
          @include res(font-size,.18rem,(md:.28rem,sm:.38rem,xs:.36rem));
        }
        .desc{
          line-height: 1.6;
          transition: all .3s;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          @include res(opacity,0,(md:1));
          @include res(transform, translateY(80%),(md:translateY(0%)));
          @include res(margin-top,.2rem);
        }
        .link{
          transition: all .3s;
          display: flex;
          align-items: center;
          @include res(opacity,0,(md:1));
          @include res(transform, translateY(150%),(md:translateY(0%)));
          @include res(margin-top,.25rem);
          .iconfont{
            @include res(font-size,.22rem,(md:.32rem,sm:.42rem,xs:.3rem));
            @include res(margin-left,.1rem);
          }
        }
      }
      &:hover{
        .item-img{
          &::after{
            background-color: rgba(0,0,0,.2);
          }
          b{
            transform: scale(1.05);
          }
        }
        .item-text{
          .title{
            transition-delay: .1s;
            @include res(transform, translateY(0%));
          }
          .desc{
            transition-delay: .3s;
            @include res(opacity,1);
            @include res(transform, translateY(0%));
          }
          .link{
            transition-delay: .5s;
            @include res(opacity,1);
            @include res(transform, translateY(0%));
          }
        }
      }
    }
  }
}

.section5{
  background-color: #f7f7f7;
  @include res(padding-top,1rem,(xs:.6rem));
  @include res(padding-bottom,1rem,(xs:.6rem));
  .cont{
    justify-content: space-between;
    @include res(display,flex,(sm:block));
    .left{
      flex-shrink: 0;
      position: relative;
      @include res(width,31.57%,(sm:100%));
      @include res(margin-bottom,0,(sm:.5rem,xs:.3rem));
      @include res(padding-left,0,(sm:.8rem,xs:.6rem));
      @include res(padding-right,0,(sm:.8rem,xs:.6rem));
      @include res(padding-top,.3rem,(sm:0));
      @include res(padding-bottom,.3rem,(sm:0));
      .swiper{
        @include res(height,30vw,(sm:auto));
        .swiper-slide{
          background-color: #fff;
          border-style: solid;
          border-color: #fff;
          transition: border-color .3s;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          cursor: pointer;
          @include res(height,null,(sm:auto));
          @include res(border-width,.01rem,(md:1px));
          .item{
            line-height: 1.6;
            @include res(padding-left,.3rem);
            @include res(padding-right,.3rem);
            @include res(padding-top,0,(sm:.5rem));
            @include res(padding-bottom,0,(sm:.5rem));
            @include res(font-size,.18rem,(md:.28rem,sm:.38rem,xs:.32rem));
            >div{
              display: flex;
              align-items: center;
              &:not(:last-child){
                @include res(margin-bottom,.3rem,(xs:.2rem));
              }
              .iconfont{
                color: $color-main;
                line-height: 1;
                @include res(margin-right,.3rem);
                @include res(font-size,.4rem,(md:.5rem,sm:.6rem,xs:.4rem));
              }
            }
          }
          &.active{
            color: $color-main;
            border-color: $color-main;
            p{
              font-weight: bold;
            }
          }
        }
      }
      .swiper-btn{
        display: flex;
        align-items: center;
        justify-content: center;
        @include res(margin-top,.1rem);
        .swiper-button-next,.swiper-button-prev{
          margin: 0;
          @include res(position,absolute);
          &::after{
            color: #333;
            @include res(font-size, .4rem,(md:.5rem,sm:.6rem,xs:.4rem));
          }
        }
        .swiper-button-prev{
          @include res(left,50%,(sm:0));
          @include res(top,-.1rem,(sm:50%));
          @include res(transform,rotate(90deg) translateX(-50%) translateY(30%),(sm:rotate(0deg) translateX(0%) translateY(-50%)));
        }
        .swiper-button-next{
          @include res(right,50%,(sm:0));
          @include res(bottom,0,(sm:auto));
          @include res(top,auto,(sm:50%));
          @include res(transform,rotate(90deg) translateX(50%) translateY(-30%),(sm:rotate(0deg) translateX(0%) translateY(-50%)));
        }
      }
    }
    .right{
      flex-shrink: 0;
      @include res(width,65.13%,(sm:100%));
      @include res(height,null,(sm:50vw,xs:70vw));
      .map{
        width: 100%;
        height: 100%;
      }
    }
  }
}

.section6{
  justify-content: space-between;
  flex-wrap: wrap;
  @include res(display,flex);
  @include res(padding-top,.8rem,(xs:.3rem));
  @include res(padding-bottom,1rem);
  .item{
    flex-shrink: 0;
    text-align: center;
    @include res(width,16.66%,(xs:33.33%));
    @include res(margin-top,0,(xs:.5rem));
    .img{
      position: relative;
      display: inline-block;
      z-index: 1;
      img{
        height: auto;
        transition: all .3s;
        display: block;
        transform-origin: center;
        @include res(width,.8rem,(md:.9rem,sm:1.1rem,xs:.8rem));
        &:nth-child(2){
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
          transform: translate3d(15%,-5%,0);
        }
      }
    }
    p{
      text-align: center;
      @include res(margin-top,.2rem,(xs:.1rem));
    }
    &:hover{
      .img{
        img{
          &:nth-child(1){
            transform: scale(1.1);
          }
          &:nth-child(2){
            transform: scale(1.05) translate3d(20%,-10%,0);
          }
        }
      }
    }
  }
}