body {
  background-color: #fff;
}

.section1 {
  height: calc(100vh - .4rem);
}

.section1 .swiper {
  height: 100%;
}

.section1 .swiper .swiper-slide {
  overflow: hidden;
}

.section1 .swiper .swiper-slide::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.section1 .swiper .swiper-slide b {
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}

.section1 .swiper .swiper-slide .banner-t {
  position: absolute;
  top: 50%;
  color: #fff;
  width: 100%;
  text-align: center;
  transform: translateY(-50%);
  z-index: 2;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
}

.section1 .swiper .swiper-slide .banner-t h1 {
  font-family: 'song';
  font-size: 0.6rem;
  margin-bottom: 0.2rem;
}

@media (max-width: 1024px) {
  .section1 .swiper .swiper-slide .banner-t h1 {
    font-size: 0.7rem;
  }
}

@media (max-width: 991px) {
  .section1 .swiper .swiper-slide .banner-t h1 {
    font-size: 0.8rem;
  }
}

@media (max-width: 767px) {
  .section1 .swiper .swiper-slide .banner-t h1 {
    font-size: 0.5rem;
  }
}

.section1 .swiper .swiper-slide .banner-t h2 {
  font-family: 'Arial','DIN-Medium';
  font-weight: bold;
  font-size: 0.24rem;
}

@media (max-width: 1024px) {
  .section1 .swiper .swiper-slide .banner-t h2 {
    font-size: 0.34rem;
  }
}

@media (max-width: 991px) {
  .section1 .swiper .swiper-slide .banner-t h2 {
    font-size: 0.44rem;
  }
}

@media (max-width: 767px) {
  .section1 .swiper .swiper-slide .banner-t h2 {
    font-size: 0.3rem;
  }
}

.section1 .swiper .swiper-pagination {
  bottom: 0.7rem;
}

.section1 .swiper .swiper-pagination .swiper-pagination-bullet {
  background-color: transparent;
  border-style: solid;
  border-color: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: all .3s;
  margin: 0 0.15rem;
  border-width: 0.01rem;
  width: 0.18rem;
  height: 0.18rem;
}

@media (max-width: 1024px) {
  .section1 .swiper .swiper-pagination .swiper-pagination-bullet {
    border-width: 1px;
  }
}

@media (max-width: 1024px) {
  .section1 .swiper .swiper-pagination .swiper-pagination-bullet {
    width: 18px;
  }
}

@media (max-width: 1024px) {
  .section1 .swiper .swiper-pagination .swiper-pagination-bullet {
    height: 18px;
  }
}

.section1 .swiper .swiper-pagination .swiper-pagination-bullet::after {
  content: '';
  display: block;
  border-radius: 50%;
  background-color: #fff;
  width: 0.1rem;
  height: 0.1rem;
}

@media (max-width: 1024px) {
  .section1 .swiper .swiper-pagination .swiper-pagination-bullet::after {
    width: 10px;
  }
}

@media (max-width: 1024px) {
  .section1 .swiper .swiper-pagination .swiper-pagination-bullet::after {
    height: 10px;
  }
}

.section1 .swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  border-color: #fff;
}

.section2 {
  align-items: stretch;
  flex-wrap: wrap;
  display: flex;
  margin-bottom: 1rem;
}

@media (max-width: 767px) {
  .section2 {
    margin-bottom: 0.6rem;
  }
}

.section2 .item {
  flex-shrink: 0;
  position: relative;
  flex-grow: 1;
  border-bottom: solid #f1f1f1;
  border-bottom-width: 0.01rem;
  width: 20%;
}

@media (max-width: 1024px) {
  .section2 .item {
    border-bottom-width: 1px;
  }
}

@media (max-width: 767px) {
  .section2 .item {
    width: 50%;
  }
}

.section2 .item:not(:last-child)::after {
  content: '';
  background-color: #f1f1f1;
  position: absolute;
  right: 0;
  top: 34%;
  transition: opacity .3s;
  width: 0.01rem;
  height: 32%;
}

@media (max-width: 1024px) {
  .section2 .item:not(:last-child)::after {
    width: 1px;
  }
}

@media (max-width: 767px) {
  .section2 .item:nth-child(even)::after {
    display: none;
  }
}

.section2 .item .show-cont {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: color .3s;
  padding-top: 0.15rem;
  padding-bottom: 0.15rem;
}

.section2 .item .show-cont::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, #41b8ef, #187ec6);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  transition: opacity .3s;
}

.section2 .item .show-cont img {
  width: auto;
  height: 0.9rem;
  margin-right: 0.25rem;
}

@media (max-width: 1024px) {
  .section2 .item .show-cont img {
    height: 1rem;
  }
}

@media (max-width: 991px) {
  .section2 .item .show-cont img {
    height: 1.2rem;
  }
}

@media (max-width: 767px) {
  .section2 .item .show-cont img {
    height: 0.8rem;
  }
}

.section2 .item .show-cont p {
  font-size: 0.2rem;
}

@media (max-width: 1024px) {
  .section2 .item .show-cont p {
    font-size: 0.3rem;
  }
}

@media (max-width: 991px) {
  .section2 .item .show-cont p {
    font-size: 0.4rem;
  }
}

@media (max-width: 767px) {
  .section2 .item .show-cont p {
    font-size: 0.3rem;
  }
}

.section2 .item .hidden-cont {
  position: absolute;
  left: 50%;
  z-index: 2;
  transform: translateX(-50%);
  background-color: #fff;
  transition: all .3s;
  opacity: 0;
  visibility: hidden;
  bottom: calc(100% + .1rem);
  padding: 0.05rem;
}

@media (max-width: 1024px) {
  .section2 .item .hidden-cont {
    bottom: calc(100% + .15rem);
  }
}

.section2 .item .hidden-cont::after {
  content: '';
  display: block;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 0.1rem;
}

@media (max-width: 1024px) {
  .section2 .item .hidden-cont::after {
    border-width: 0.15rem;
  }
}

.section2 .item .hidden-cont img {
  display: block;
  height: auto;
  width: 1.3rem;
}

@media (max-width: 1024px) {
  .section2 .item .hidden-cont img {
    width: 1.6rem;
  }
}

@media (max-width: 991px) {
  .section2 .item .hidden-cont img {
    width: 1.8rem;
  }
}

.section2 .item:hover::after {
  opacity: 0;
}

.section2 .item:hover .show-cont {
  color: #fff;
  font-weight: bold;
}

.section2 .item:hover .show-cont::after {
  opacity: 1;
}

.section2 .item:hover .hidden-cont {
  opacity: 1;
  visibility: visible;
}

.section3 {
  position: relative;
  align-items: flex-start;
  justify-content: space-between;
  display: flex;
  margin-bottom: 0.9rem;
}

@media (max-width: 991px) {
  .section3 {
    display: block;
  }
}

@media (max-width: 767px) {
  .section3 {
    margin-bottom: 0.6rem;
  }
}

.section3 .bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  pointer-events: none;
}

.section3 .bg img {
  display: block;
  height: auto;
  width: 7.1rem;
}

@media (max-width: 991px) {
  .section3 .bg img {
    width: 8rem;
  }
}

@media (max-width: 767px) {
  .section3 .bg img {
    width: 6rem;
  }
}

.section3 .line {
  width: 100%;
  background-color: #e0e0e0;
  height: 0.01rem;
  margin-bottom: 0.4rem;
}

@media (max-width: 1024px) {
  .section3 .line {
    height: 1px;
  }
}

.section3 .line::before {
  content: '';
  display: block;
  height: 100%;
  background-color: #00538c;
  width: 1.8rem;
}

.section3 .left-cont {
  flex-shrink: 0;
  width: 64.5%;
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .section3 .left-cont {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .section3 .left-cont {
    margin-bottom: 0.9rem;
  }
}

@media (max-width: 767px) {
  .section3 .left-cont {
    margin-bottom: 0.6rem;
  }
}

.section3 .left-cont .headline {
  background-color: #f7f7f7;
  margin-bottom: 0.3rem;
}

.section3 .left-cont .headline a {
  justify-content: space-between;
  display: flex;
}

@media (max-width: 767px) {
  .section3 .left-cont .headline a {
    display: block;
  }
}

.section3 .left-cont .headline a .img {
  flex-shrink: 0;
  overflow: hidden;
  height: 18.7vw;
  width: 52%;
}

@media (max-width: 1024px) {
  .section3 .left-cont .headline a .img {
    height: 21.7vw;
  }
}

@media (max-width: 991px) {
  .section3 .left-cont .headline a .img {
    height: 30.7vw;
  }
}

@media (max-width: 767px) {
  .section3 .left-cont .headline a .img {
    height: 62.7vw;
  }
}

@media (max-width: 767px) {
  .section3 .left-cont .headline a .img {
    width: 100%;
  }
}

.section3 .left-cont .headline a .img b {
  display: block;
  height: 100%;
  transition: all .3s;
}

.section3 .left-cont .headline a .text {
  flex-shrink: 0;
  position: relative;
  padding-right: 0.25rem;
  padding-left: 0;
  padding-bottom: 0;
  width: 44%;
}

@media (max-width: 767px) {
  .section3 .left-cont .headline a .text {
    padding-left: 0.25rem;
  }
}

@media (max-width: 767px) {
  .section3 .left-cont .headline a .text {
    padding-bottom: 0.25rem;
  }
}

@media (max-width: 767px) {
  .section3 .left-cont .headline a .text {
    width: 100%;
  }
}

.section3 .left-cont .headline a .text .date {
  font-family: 'din';
  display: inline-block;
  background-color: #00538c;
  color: #fff;
  text-align: center;
  font-size: 0.14rem;
  padding: 0.1rem 0.2rem;
}

@media (max-width: 1024px) {
  .section3 .left-cont .headline a .text .date {
    font-size: 0.24rem;
  }
}

@media (max-width: 991px) {
  .section3 .left-cont .headline a .text .date {
    font-size: 0.34rem;
  }
}

@media (max-width: 767px) {
  .section3 .left-cont .headline a .text .date {
    font-size: 0.24rem;
  }
}

.section3 .left-cont .headline a .text .date span {
  display: block;
  font-size: 0.24rem;
}

@media (max-width: 1024px) {
  .section3 .left-cont .headline a .text .date span {
    font-size: 0.34rem;
  }
}

@media (max-width: 991px) {
  .section3 .left-cont .headline a .text .date span {
    font-size: 0.44rem;
  }
}

@media (max-width: 767px) {
  .section3 .left-cont .headline a .text .date span {
    font-size: 0.3rem;
  }
}

.section3 .left-cont .headline a .text .title {
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: color .3s;
  margin-top: 0.35rem;
  margin-bottom: 0.1rem;
  font-size: 0.18rem;
}

@media (max-width: 991px) {
  .section3 .left-cont .headline a .text .title {
    margin-bottom: 0.2rem;
  }
}

@media (max-width: 1024px) {
  .section3 .left-cont .headline a .text .title {
    font-size: 0.28rem;
  }
}

@media (max-width: 991px) {
  .section3 .left-cont .headline a .text .title {
    font-size: 0.38rem;
  }
}

@media (max-width: 767px) {
  .section3 .left-cont .headline a .text .title {
    font-size: 0.32rem;
  }
}

.section3 .left-cont .headline a .text .desc {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: #666;
  line-height: 1.6;
}

.section3 .left-cont .headline a .text .link {
  left: 0;
  bottom: 0.2rem;
  position: absolute;
  margin-top: 0;
}

@media (max-width: 767px) {
  .section3 .left-cont .headline a .text .link {
    position: static;
  }
}

@media (max-width: 767px) {
  .section3 .left-cont .headline a .text .link {
    margin-top: 0.3rem;
  }
}

.section3 .left-cont .headline a .text .link .iconfont {
  color: #00538c;
  line-height: 1;
  font-size: 0.4rem;
  display: block;
  margin-left: 0;
}

@media (max-width: 1024px) {
  .section3 .left-cont .headline a .text .link .iconfont {
    font-size: 0.5rem;
  }
}

@media (max-width: 991px) {
  .section3 .left-cont .headline a .text .link .iconfont {
    font-size: 0.6rem;
  }
}

@media (max-width: 767px) {
  .section3 .left-cont .headline a .text .link .iconfont {
    font-size: 0.3rem;
  }
}

@media (max-width: 767px) {
  .section3 .left-cont .headline a .text .link .iconfont {
    display: inline-block;
  }
}

@media (max-width: 767px) {
  .section3 .left-cont .headline a .text .link .iconfont {
    margin-left: 0.1rem;
  }
}

.section3 .left-cont .headline:hover a .img b {
  transform: scale(1.05);
}

.section3 .left-cont .headline:hover a .text .title {
  color: #00538c;
}

.section3 .left-cont ul {
  justify-content: space-between;
  display: flex;
}

@media (max-width: 767px) {
  .section3 .left-cont ul {
    display: block;
  }
}

.section3 .left-cont ul li {
  flex-shrink: 0;
  width: 45%;
}

@media (max-width: 767px) {
  .section3 .left-cont ul li {
    width: 100%;
  }
}

.section3 .left-cont ul li:not(:last-child) {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .section3 .left-cont ul li:not(:last-child) {
    margin-bottom: 0.3rem;
  }
}

.section3 .left-cont ul li .date {
  font-family: 'din';
}

.section3 .left-cont ul li .date span {
  display: inline-block;
  color: #fff;
  background-color: #00538c;
  font-size: 0.14rem;
  padding: 0.1rem 0.2rem;
}

@media (max-width: 1024px) {
  .section3 .left-cont ul li .date span {
    font-size: 0.24rem;
  }
}

@media (max-width: 991px) {
  .section3 .left-cont ul li .date span {
    font-size: 0.34rem;
  }
}

@media (max-width: 767px) {
  .section3 .left-cont ul li .date span {
    font-size: 0.24rem;
  }
}

@media (max-width: 1024px) {
  .section3 .left-cont ul li .date span {
    padding: 0.15rem 0.3rem;
  }
}

.section3 .left-cont ul li .title {
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: color .3s;
  margin-top: 0.2rem;
  margin-bottom: 0.1rem;
  font-size: 0.18rem;
}

@media (max-width: 991px) {
  .section3 .left-cont ul li .title {
    margin-bottom: 0.2rem;
  }
}

@media (max-width: 1024px) {
  .section3 .left-cont ul li .title {
    font-size: 0.28rem;
  }
}

@media (max-width: 991px) {
  .section3 .left-cont ul li .title {
    font-size: 0.38rem;
  }
}

@media (max-width: 767px) {
  .section3 .left-cont ul li .title {
    font-size: 0.32rem;
  }
}

.section3 .left-cont ul li .desc {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: #666;
  line-height: 1.6;
}

.section3 .left-cont ul li:hover .title {
  color: #00538c;
}

.section3 .right-cont {
  flex-shrink: 0;
  width: 28.8%;
}

@media (max-width: 1024px) {
  .section3 .right-cont {
    width: 32%;
  }
}

@media (max-width: 991px) {
  .section3 .right-cont {
    width: 100%;
  }
}

.section3 .right-cont ul {
  background-color: #f7f7f7;
  padding: 0 0.25rem;
}

.section3 .right-cont ul li:not(:last-child) {
  border-bottom: solid #e0e0e0;
  border-bottom-width: 0.01rem;
}

@media (max-width: 1024px) {
  .section3 .right-cont ul li:not(:last-child) {
    border-bottom-width: 1px;
  }
}

.section3 .right-cont ul li a {
  display: block;
  transition: color .3s;
  line-height: 1.6;
  padding: 0.2rem 0;
}

.section3 .right-cont ul li a p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-weight: bold;
  font-size: 0.18rem;
  margin-bottom: 0.1rem;
}

@media (max-width: 1024px) {
  .section3 .right-cont ul li a p {
    font-size: 0.28rem;
  }
}

@media (max-width: 991px) {
  .section3 .right-cont ul li a p {
    font-size: 0.38rem;
  }
}

@media (max-width: 767px) {
  .section3 .right-cont ul li a p {
    font-size: 0.32rem;
  }
}

.section3 .right-cont ul li:hover a {
  color: #00538c;
}

.section4 {
  margin-bottom: 1rem;
}

@media (max-width: 767px) {
  .section4 {
    margin-bottom: 0.6rem;
  }
}

.section4 ul {
  justify-content: flex-start;
  display: flex;
  width: calc(100% + .5rem);
}

@media (max-width: 767px) {
  .section4 ul {
    display: block;
  }
}

@media (max-width: 767px) {
  .section4 ul {
    width: 100%;
  }
}

.section4 ul li {
  position: relative;
  height: 14.58vw;
  width: calc(33.33% - .5rem);
  margin-right: 0.5rem;
}

@media (max-width: 1024px) {
  .section4 ul li {
    height: 17.58vw;
  }
}

@media (max-width: 767px) {
  .section4 ul li {
    height: 55vw;
  }
}

@media (max-width: 767px) {
  .section4 ul li {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .section4 ul li {
    margin-right: 0;
  }
}

.section4 ul li:not(:last-child) {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .section4 ul li:not(:last-child) {
    margin-bottom: 0.3rem;
  }
}

.section4 ul li .item-img {
  height: 100%;
  overflow: hidden;
  position: relative;
}

.section4 ul li .item-img::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transition: all .3s;
  background-color: rgba(0, 0, 0, 0.1);
}

@media (max-width: 1024px) {
  .section4 ul li .item-img::after {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.section4 ul li .item-img b {
  display: block;
  height: 100%;
  transition: all .3s;
}

.section4 ul li .item-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20%;
}

@media (max-width: 1024px) {
  .section4 ul li .item-text {
    padding: 0 5%;
  }
}

.section4 ul li .item-text .title {
  font-weight: bold;
  transition: all .3s;
  transform: translateY(300%);
  font-size: 0.18rem;
}

@media (max-width: 1024px) {
  .section4 ul li .item-text .title {
    transform: translateY(0%);
  }
}

@media (max-width: 1024px) {
  .section4 ul li .item-text .title {
    font-size: 0.28rem;
  }
}

@media (max-width: 991px) {
  .section4 ul li .item-text .title {
    font-size: 0.38rem;
  }
}

@media (max-width: 767px) {
  .section4 ul li .item-text .title {
    font-size: 0.36rem;
  }
}

.section4 ul li .item-text .desc {
  line-height: 1.6;
  transition: all .3s;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  opacity: 0;
  transform: translateY(80%);
  margin-top: 0.2rem;
}

@media (max-width: 1024px) {
  .section4 ul li .item-text .desc {
    opacity: 1;
  }
}

@media (max-width: 1024px) {
  .section4 ul li .item-text .desc {
    transform: translateY(0%);
  }
}

.section4 ul li .item-text .link {
  transition: all .3s;
  display: flex;
  align-items: center;
  opacity: 0;
  transform: translateY(150%);
  margin-top: 0.25rem;
}

@media (max-width: 1024px) {
  .section4 ul li .item-text .link {
    opacity: 1;
  }
}

@media (max-width: 1024px) {
  .section4 ul li .item-text .link {
    transform: translateY(0%);
  }
}

.section4 ul li .item-text .link .iconfont {
  font-size: 0.22rem;
  margin-left: 0.1rem;
}

@media (max-width: 1024px) {
  .section4 ul li .item-text .link .iconfont {
    font-size: 0.32rem;
  }
}

@media (max-width: 991px) {
  .section4 ul li .item-text .link .iconfont {
    font-size: 0.42rem;
  }
}

@media (max-width: 767px) {
  .section4 ul li .item-text .link .iconfont {
    font-size: 0.3rem;
  }
}

.section4 ul li:hover .item-img::after {
  background-color: rgba(0, 0, 0, 0.2);
}

.section4 ul li:hover .item-img b {
  transform: scale(1.05);
}

.section4 ul li:hover .item-text .title {
  transition-delay: .1s;
  transform: translateY(0%);
}

.section4 ul li:hover .item-text .desc {
  transition-delay: .3s;
  opacity: 1;
  transform: translateY(0%);
}

.section4 ul li:hover .item-text .link {
  transition-delay: .5s;
  opacity: 1;
  transform: translateY(0%);
}

.section5 {
  background-color: #f7f7f7;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

@media (max-width: 767px) {
  .section5 {
    padding-top: 0.6rem;
  }
}

@media (max-width: 767px) {
  .section5 {
    padding-bottom: 0.6rem;
  }
}

.section5 .cont {
  justify-content: space-between;
  display: flex;
}

@media (max-width: 991px) {
  .section5 .cont {
    display: block;
  }
}

.section5 .cont .left {
  flex-shrink: 0;
  position: relative;
  width: 31.57%;
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

@media (max-width: 991px) {
  .section5 .cont .left {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .section5 .cont .left {
    margin-bottom: 0.5rem;
  }
}

@media (max-width: 767px) {
  .section5 .cont .left {
    margin-bottom: 0.3rem;
  }
}

@media (max-width: 991px) {
  .section5 .cont .left {
    padding-left: 0.8rem;
  }
}

@media (max-width: 767px) {
  .section5 .cont .left {
    padding-left: 0.6rem;
  }
}

@media (max-width: 991px) {
  .section5 .cont .left {
    padding-right: 0.8rem;
  }
}

@media (max-width: 767px) {
  .section5 .cont .left {
    padding-right: 0.6rem;
  }
}

@media (max-width: 991px) {
  .section5 .cont .left {
    padding-top: 0;
  }
}

@media (max-width: 991px) {
  .section5 .cont .left {
    padding-bottom: 0;
  }
}

.section5 .cont .left .swiper {
  height: 30vw;
}

@media (max-width: 991px) {
  .section5 .cont .left .swiper {
    height: auto;
  }
}

.section5 .cont .left .swiper .swiper-slide {
  background-color: #fff;
  border-style: solid;
  border-color: #fff;
  transition: border-color .3s;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  border-width: 0.01rem;
}

@media (max-width: 991px) {
  .section5 .cont .left .swiper .swiper-slide {
    height: auto;
  }
}

@media (max-width: 1024px) {
  .section5 .cont .left .swiper .swiper-slide {
    border-width: 1px;
  }
}

.section5 .cont .left .swiper .swiper-slide .item {
  line-height: 1.6;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 0.18rem;
}

@media (max-width: 991px) {
  .section5 .cont .left .swiper .swiper-slide .item {
    padding-top: 0.5rem;
  }
}

@media (max-width: 991px) {
  .section5 .cont .left .swiper .swiper-slide .item {
    padding-bottom: 0.5rem;
  }
}

@media (max-width: 1024px) {
  .section5 .cont .left .swiper .swiper-slide .item {
    font-size: 0.28rem;
  }
}

@media (max-width: 991px) {
  .section5 .cont .left .swiper .swiper-slide .item {
    font-size: 0.38rem;
  }
}

@media (max-width: 767px) {
  .section5 .cont .left .swiper .swiper-slide .item {
    font-size: 0.32rem;
  }
}

.section5 .cont .left .swiper .swiper-slide .item > div {
  display: flex;
  align-items: center;
}

.section5 .cont .left .swiper .swiper-slide .item > div:not(:last-child) {
  margin-bottom: 0.3rem;
}

@media (max-width: 767px) {
  .section5 .cont .left .swiper .swiper-slide .item > div:not(:last-child) {
    margin-bottom: 0.2rem;
  }
}

.section5 .cont .left .swiper .swiper-slide .item > div .iconfont {
  color: #00538c;
  line-height: 1;
  margin-right: 0.3rem;
  font-size: 0.4rem;
}

@media (max-width: 1024px) {
  .section5 .cont .left .swiper .swiper-slide .item > div .iconfont {
    font-size: 0.5rem;
  }
}

@media (max-width: 991px) {
  .section5 .cont .left .swiper .swiper-slide .item > div .iconfont {
    font-size: 0.6rem;
  }
}

@media (max-width: 767px) {
  .section5 .cont .left .swiper .swiper-slide .item > div .iconfont {
    font-size: 0.4rem;
  }
}

.section5 .cont .left .swiper .swiper-slide.active {
  color: #00538c;
  border-color: #00538c;
}

.section5 .cont .left .swiper .swiper-slide.active p {
  font-weight: bold;
}

.section5 .cont .left .swiper-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.1rem;
}

.section5 .cont .left .swiper-btn .swiper-button-next, .section5 .cont .left .swiper-btn .swiper-button-prev {
  margin: 0;
  position: absolute;
}

.section5 .cont .left .swiper-btn .swiper-button-next::after, .section5 .cont .left .swiper-btn .swiper-button-prev::after {
  color: #333;
  font-size: 0.4rem;
}

@media (max-width: 1024px) {
  .section5 .cont .left .swiper-btn .swiper-button-next::after, .section5 .cont .left .swiper-btn .swiper-button-prev::after {
    font-size: 0.5rem;
  }
}

@media (max-width: 991px) {
  .section5 .cont .left .swiper-btn .swiper-button-next::after, .section5 .cont .left .swiper-btn .swiper-button-prev::after {
    font-size: 0.6rem;
  }
}

@media (max-width: 767px) {
  .section5 .cont .left .swiper-btn .swiper-button-next::after, .section5 .cont .left .swiper-btn .swiper-button-prev::after {
    font-size: 0.4rem;
  }
}

.section5 .cont .left .swiper-btn .swiper-button-prev {
  left: 50%;
  top: -0.1rem;
  transform: rotate(90deg) translateX(-50%) translateY(30%);
}

@media (max-width: 991px) {
  .section5 .cont .left .swiper-btn .swiper-button-prev {
    left: 0;
  }
}

@media (max-width: 991px) {
  .section5 .cont .left .swiper-btn .swiper-button-prev {
    top: 50%;
  }
}

@media (max-width: 991px) {
  .section5 .cont .left .swiper-btn .swiper-button-prev {
    transform: rotate(0deg) translateX(0%) translateY(-50%);
  }
}

.section5 .cont .left .swiper-btn .swiper-button-next {
  right: 50%;
  bottom: 0;
  top: auto;
  transform: rotate(90deg) translateX(50%) translateY(-30%);
}

@media (max-width: 991px) {
  .section5 .cont .left .swiper-btn .swiper-button-next {
    right: 0;
  }
}

@media (max-width: 991px) {
  .section5 .cont .left .swiper-btn .swiper-button-next {
    bottom: auto;
  }
}

@media (max-width: 991px) {
  .section5 .cont .left .swiper-btn .swiper-button-next {
    top: 50%;
  }
}

@media (max-width: 991px) {
  .section5 .cont .left .swiper-btn .swiper-button-next {
    transform: rotate(0deg) translateX(0%) translateY(-50%);
  }
}

.section5 .cont .right {
  flex-shrink: 0;
  width: 65.13%;
}

@media (max-width: 991px) {
  .section5 .cont .right {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .section5 .cont .right {
    height: 50vw;
  }
}

@media (max-width: 767px) {
  .section5 .cont .right {
    height: 70vw;
  }
}

.section5 .cont .right .map {
  width: 100%;
  height: 100%;
}

.section6 {
  justify-content: space-between;
  flex-wrap: wrap;
  display: flex;
  padding-top: 0.8rem;
  padding-bottom: 1rem;
}

@media (max-width: 767px) {
  .section6 {
    padding-top: 0.3rem;
  }
}

.section6 .item {
  flex-shrink: 0;
  text-align: center;
  width: 16.66%;
  margin-top: 0;
}

@media (max-width: 767px) {
  .section6 .item {
    width: 33.33%;
  }
}

@media (max-width: 767px) {
  .section6 .item {
    margin-top: 0.5rem;
  }
}

.section6 .item .img {
  position: relative;
  display: inline-block;
  z-index: 1;
}

.section6 .item .img img {
  height: auto;
  transition: all .3s;
  display: block;
  transform-origin: center;
  width: 0.8rem;
}

@media (max-width: 1024px) {
  .section6 .item .img img {
    width: 0.9rem;
  }
}

@media (max-width: 991px) {
  .section6 .item .img img {
    width: 1.1rem;
  }
}

@media (max-width: 767px) {
  .section6 .item .img img {
    width: 0.8rem;
  }
}

.section6 .item .img img:nth-child(2) {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transform: translate3d(15%, -5%, 0);
}

.section6 .item p {
  text-align: center;
  margin-top: 0.2rem;
}

@media (max-width: 767px) {
  .section6 .item p {
    margin-top: 0.1rem;
  }
}

.section6 .item:hover .img img:nth-child(1) {
  transform: scale(1.1);
}

.section6 .item:hover .img img:nth-child(2) {
  transform: scale(1.05) translate3d(20%, -10%, 0);
}
